<template>
  <div class="wrap">
    <Navbar :title="$t('page.financial')">
      <template #right>
        <div @click="handleLinkPage('FinancialRecord')" style="
            padding: calc(6rem / 16) calc(12rem / 16);
            background: #fff;
            color: var(--theme);
            border-radius: 4px;
            font-size: calc(14rem / 16);
          ">
          {{ $t("newAdd.Record") }}
        </div>
      </template>
    </Navbar>
    <div class="content">
      <div class="info">
        <div class="left">
          <div class="title">{{ $t("totalDeposit") }}</div>
          <div class="value">{{ userInfo.balance || "0.00" }}</div>
        </div>
        <div class="right">
          <div class="title">{{ $t("totalRevenue") }}</div>
          <div class="value">{{ userInfo.yuebao_income_amount || "0.00" }}</div>
        </div>
      </div>
      <div class="btns">
        <div class="into" @click="handleLinkPage('TransferInto')">
          <img src="../../assets/financial/gold.png" alt="" />
          <div class="text">{{ $t("btn.transferInto") }}</div>
        </div>
        <div class="out" @click="handleLinkPage('TransferOut')">
          <img src="../../assets/financial/me_gold.png" alt="" />
          <div class="text">{{ $t("btn.transferOut") }}</div>
        </div>
      </div>
      <div class="list_wrap">
        <FinancialRecord />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Toast } from "vant";
import { getToken } from "@/utils/tools";
import { $post } from "@/utils/request";
import FinancialRecord from "./components/financialRecordCom.vue";
import Navbar from '@/components/JNav'
export default {
  components: { [Icon.name]: Icon, FinancialRecord, Navbar },
  data() {
    return {
      reqNum: 0,
      userInfo: {},
    };
  },
  created() {
    // Toast.loading();
    this.getUserInfo();
    this.getBalance();
  },
  methods: {
    // 页面跳转
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    getUserInfo() {
      this.reqNum++;
      this.$api.getUserInfo(
        (res) => {
          this.reqNum--;
          this.userInfo = { ...this.userInfo, ...res };
        },
        (err) => {
          this.reqNum--;
        }
      );
    },
    async getBalance() {
      this.reqNum++;
      try {
        const res = await this.$api.getFinancialBalance();
        this.userInfo = { ...this.userInfo, ...res };
        this.reqNum--;
      } catch (error) {
        this.reqNum--;
        // this.$toast(error);
        throw new Error(error)
        console.log(new Error(error))
      }
    },
  },
  watch: {
    reqNum(value) {
      if (value <= 0) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f7f8fc;
  --nav-background: #ff0000;
  --theme: #ff0000;

  /deep/ .van-nav-bar {
    background: var(--nav-background);
  }

  /deep/ .van-nav-bar__title {
    color: var(--textColor) !important;
  }
}

.content {
  height: calc(120rem / 16);
  background: linear-gradient(315deg, #a78e6f, #d5bd9d);
  border-radius: 0 0 calc(16rem / 16) calc(16rem / 16);
  position: relative;

  .info {
    height: calc(100rem / 16);
    padding: 0 calc(20rem / 16);
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div {
      text-align: center;
      color: var(--textColor);

      .title {
        font-size: calc(14rem / 16);
        font-weight: 400;
        opacity: 0.8;
      }

      .value {
        font-size: calc(30rem / 16);
        line-height: calc(40rem / 16);
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(16rem / 16);

    >div {
      display: flex;
      padding: calc(10rem / 16) calc(15rem / 16);
      background: #fff;
      border-radius: calc(8rem / 16);
      box-shadow: 0 2px 12px 0 rgba(114, 114, 154, 10%);

      img {
        width: calc(30rem / 16);
        height: calc(27rem / 16);
      }

      .text {
        font-weight: 600;
        font-size: calc(14rem / 16);
        color: #636f85;
        margin-left: calc(10rem / 16);
        line-height: calc(27rem / 16);
      }
    }
  }
}
</style>